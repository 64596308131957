<template>
  <fragment>
    <!--LIST-->
    <td
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxNotificationVer"
          hide-details
          class="my-1"
          @click="checkAllListBooking()"
        ></v-checkbox>
      </div>
    </td>
    <!--EDIT-->
    <td
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxNotificationEdit"
          hide-details
          class="my-1"
          @click="checkAllEditBooking()"
        ></v-checkbox>
      </div>
    </td>
    <!--CREATE-->
    <td
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxNotificationCreate"
          hide-details
          class="my-1"
          @click="checkAllCreateBooking()"
        ></v-checkbox>
      </div>
    </td>
    <!--DESTROY-->
    <td
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxNotificationDelete"
          hide-details
          class="my-1"
          @click="checkAllDestroyBooking()"
        ></v-checkbox>
      </div>
    </td>
    <td
      :key="Math.random() * (index + 1)"
      style="min-width: 50px;"
    ></td>
  </fragment>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    index: {
      type: Number,
    },
  },
  data() {
    return {
      permisosLogin: localStorage.getItem('permisos_auth'),
      user: JSON.parse(localStorage.getItem('profile')),
    }
  },
  computed: {
    ...mapState({
      sourcePermisos: state => state.app.sourcePermisos,
    }),
  },
  methods: {
    checkAllListBooking() {
      const { permisos } = this.sourcePermisos[this.index]

      this.$store.state.app.showNotification = false
      if (this.sourcePermisos[this.index].all.checkboxNotificationVer) {
        if (this.permisosLogin.includes('newletters:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('newletters:list')
        }
        if (this.permisosLogin.includes('dns_emails:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('dns_emails:list')
        }
        if (this.permisosLogin.includes('bot_telegram:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('bot_telegram:list')
        }

        /* if (this.permisosLogin.includes('dashboard_cards:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('dashboard_cards:list')
        } */
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'newletters:list'
            && e !== 'dns_emails:list'
            && e !== 'bot_telegram:list'
            /* && e !== 'dashboard_cards:list' */,
        )
      }
      setTimeout(() => {
        this.$store.state.app.showNotification = !this.$store.state.app.showNotification
      }, 10)
    },
    checkAllEditBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showNotification = false
      if (this.sourcePermisos[this.index].all.checkboxNotificationEdit) {
        if (this.permisosLogin.includes('newletters:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('newletters:edit')
        }
        if (this.permisosLogin.includes('dns_emails:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('dns_emails:edit')
        }
        if (this.permisosLogin.includes('bot_telegram:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('bot_telegram:edit')
        }

        /* if (this.permisosLogin.includes('dashboard_cards:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('dashboard_cards:edit')
        } */
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'newletters:edit'
            && e !== 'dns_emails:edit'
            && e !== 'bot_telegram:edit'
            /* && e !== 'dashboard_cards:edit' */,
        )
      }
      setTimeout(() => {
        this.$store.state.app.showNotification = !this.$store.state.app.showNotification
      }, 10)
    },
    checkAllCreateBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showNotification = false
      if (this.sourcePermisos[this.index].all.checkboxNotificationCreate) {
        if (this.permisosLogin.includes('newletters:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('newletters:create')
        }
        if (this.permisosLogin.includes('dns_emails:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('dns_emails:create')
        }
        if (this.permisosLogin.includes('bot_telegram:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('bot_telegram:create')
        }

        /* if (this.permisosLogin.includes('dashboard_cards:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('dashboard_cards:create')
        } */
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'newletters:create'
            && e !== 'dns_emails:create'
            && e !== 'bot_telegram:create'
            /* && e !== 'dashboard_cards:create' */,
        )
      }
      setTimeout(() => {
        this.$store.state.app.showNotification = !this.$store.state.app.showNotification
      }, 10)
    },
    checkAllDestroyBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showNotification = false
      if (this.sourcePermisos[this.index].all.checkboxNotificationDelete) {
        if (this.permisosLogin.includes('newletters:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('newletters:destroy')
        }
        if (this.permisosLogin.includes('dns_emails:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('dns_emails:destroy')
        }
        if (this.permisosLogin.includes('bot_telegram:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('bot_telegram:destroy')
        }

        /* if (this.permisosLogin.includes('dashboard_cards:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('dashboard_cards:destroy')
        } */
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'newletters:destroy'
            && e !== 'dns_emails:destroy'
            && e !== 'bot_telegram:destroy'
            /* && e !== 'dashboard_cards:destroy' */,
        )
      }
      setTimeout(() => {
        this.$store.state.app.showNotification = !this.$store.state.app.showNotification
      }, 10)
    },
  },
}
</script>
