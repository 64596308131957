<template>
  <fragment>
    <!--LIST-->
    <td
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="checkboxVer"
          hide-details
          class="my-1"
          :disabled="!permisosLogin.includes('nom_reducciones_contrate_hotels:list') && user.nivel !== -1"
          @click="checkPerm(index, 'nom_reducciones_contrate_hotels:list')"
        ></v-checkbox>
      </div>
    </td>
    <!--EDIT-->
    <td
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="checkboxEdit"
          hide-details
          class="my-1"
          :disabled="!permisosLogin.includes('nom_reducciones_contrate_hotels:edit') && user.nivel !== -1"
          @click="checkPerm(index, 'nom_reducciones_contrate_hotels:edit')"
        ></v-checkbox>
      </div>
    </td>
    <!--CREATE-->
    <td
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="checkboxCreate"
          hide-details
          class="my-1"
          :disabled="!permisosLogin.includes('nom_reducciones_contrate_hotels:create') && user.nivel !== -1"
          @click="checkPerm(index, 'nom_reducciones_contrate_hotels:create')"
        ></v-checkbox>
      </div>
    </td>
    <!--DESTROY-->
    <td
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="checkboxDelete"
          hide-details
          class="my-1"
          :disabled="!permisosLogin.includes('nom_reducciones_contrate_hotels:destroy') && user.nivel !== -1"
          @click="checkPerm(index, 'nom_reducciones_contrate_hotels:destroy')"
        ></v-checkbox>
      </div>
    </td>
    <td
      :key="Math.random() * (index + 1)"
      style="min-width: 50px;"
    ></td>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    index: {
      type: Number,
    },
  },
  computed: {
    ...mapState({
      sourcePermisos: state => state.app.sourcePermisos,
    }),
  },
  // eslint-disable-next-line vue/order-in-components
  data() {
    return {
      isLoading: true,
      checkboxVer: false,
      checkboxEdit: false,
      checkboxCreate: false,
      checkboxDelete: false,
      permisosLogin: localStorage.getItem('permisos_auth'),
      user: JSON.parse(localStorage.getItem('profile')),
    }
  },
  created() {
    this.checkboxVer = this.sourcePermisos[this.index].permisos.includes('nom_reducciones_contrate_hotels:list')
    this.checkboxEdit = this.sourcePermisos[this.index].permisos.includes('nom_reducciones_contrate_hotels:edit')
    this.checkboxCreate = this.sourcePermisos[this.index].permisos.includes('nom_reducciones_contrate_hotels:create')
    this.checkboxDelete = this.sourcePermisos[this.index].permisos.includes('nom_reducciones_contrate_hotels:destroy')
  },
  methods: {
    ...mapMutations(['verifyPermisoZona']),
    checkPerm(pos, ability) {
      let { permisos } = this.sourcePermisos[pos]
      if (permisos.includes(ability)) {
        this.sourcePermisos[pos].permisos = permisos.filter(e => e !== ability)
        if (ability === 'nom_reducciones_contrate_hotels:list') {
          this.sourcePermisos[pos].all.checkboxNomencladoresHotelsVer = false
          this.sourcePermisos[pos].all.checkboxNomencladoresVer = false
        } else if (ability === 'nom_reducciones_contrate_hotels:edit') {
          this.sourcePermisos[pos].all.checkboxNomencladoresEdit = false
          this.sourcePermisos[pos].all.checkboxNomencladoresHotelsEdit = false
        } else if (ability === 'nom_reducciones_contrate_hotels:create') {
          this.sourcePermisos[pos].all.checkboxNomencladoresCreate = false
          this.sourcePermisos[pos].all.checkboxNomencladoresHotelsCreate = false
        } else if (ability === 'nom_reducciones_contrate_hotels:destroy') {
          this.sourcePermisos[pos].all.checkboxNomencladoresDelete = false
          this.sourcePermisos[pos].all.checkboxNomencladoresHotelsDelete = false
        }
      } else {
        permisos = permisos.push(ability)
        this.verifyPermisoZona(this.index)
      }
    },
  },
}
</script>
