<template>
  <fragment>
    <!--LIST-->
    <td
      v-if="$store.state.app.showAdministration"
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxAdministrationVer"
          hide-details
          class="my-1"
          @click="checkAllListBooking()"
        ></v-checkbox>
      </div>
    </td>
    <!--EDIT-->
    <td
      v-if="$store.state.app.showAdministration"
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxAdministrationEdit"
          hide-details
          class="my-1"
          @click="checkAllEditBooking()"
        ></v-checkbox>
      </div>
    </td>
    <!--CREATE-->
    <td
      v-if="$store.state.app.showAdministration"
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxAdministrationCreate"
          hide-details
          class="my-1"
          @click="checkAllCreateBooking()"
        ></v-checkbox>
      </div>
    </td>
    <!--DESTROY-->
    <td
      v-if="$store.state.app.showAdministration"
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxAdministrationDelete"
          hide-details
          class="my-1"
          @click="checkAllDestroyBooking()"
        ></v-checkbox>
      </div>
    </td>
    <td
      :key="Math.random() * (index + 1)"
      style="min-width: 50px;"
    ></td>
  </fragment>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    index: {
      type: Number,
    },
  },
  data() {
    return {
      permisosLogin: localStorage.getItem('permisos_auth'),
      user: JSON.parse(localStorage.getItem('profile')),
    }
  },
  computed: {
    ...mapState({
      sourcePermisos: state => state.app.sourcePermisos,
    }),
  },
  methods: {
    checkAllListBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showAdministration = false
      if (this.sourcePermisos[this.index].all.checkboxAdministrationVer) {
        if (this.permisosLogin.includes('ctas_x_cobrar:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('ctas_x_cobrar:list')
        }
        if (this.permisosLogin.includes('ctas_x_pagar:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('ctas_x_pagar:list')
        }
        if (this.permisosLogin.includes('pays_recibidos:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('pays_recibidos:list')
        }
        if (this.permisosLogin.includes('pays_emitidos:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('pays_emitidos:list')
        }
        if (this.permisosLogin.includes('markup:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('markup:list')
        }
        if (this.permisosLogin.includes('finanzas:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('finanzas:list')
        }
        if (this.permisosLogin.includes('comisiones:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('comisiones:list')
        }
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'finanzas:list'
            && e !== 'ctas_x_cobrar:list'
            && e !== 'ctas_x_pagar:list'
            && e !== 'pays_recibidos:list'
            && e !== 'pays_emitidos:list'
            && e !== 'comisiones:list'
            && e !== 'markup:list',
        )
      }
      setTimeout(() => {
        this.$store.state.app.showAdministration = !this.$store.state.app.showAdministration
      }, 10)
    },
    checkAllEditBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showAdministration = false
      if (this.sourcePermisos[this.index].all.checkboxAdministrationEdit) {
        if (this.permisosLogin.includes('ctas_x_cobrar:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('ctas_x_cobrar:edit')
        }
        if (this.permisosLogin.includes('ctas_x_pagar:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('ctas_x_pagar:edit')
        }
        if (this.permisosLogin.includes('pays_recibidos:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('pays_recibidos:edit')
        }
        if (this.permisosLogin.includes('pays_emitidos:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('pays_emitidos:edit')
        }
        if (this.permisosLogin.includes('markup:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('markup:edit')
        }
        if (this.permisosLogin.includes('finanzas:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('finanzas:edit')
        }
        if (this.permisosLogin.includes('comisiones:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('comisiones:edit')
        }
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'finanzas:edit'
            && e !== 'ctas_x_cobrar:edit'
            && e !== 'ctas_x_pagar:edit'
            && e !== 'pays_recibidos:edit'
            && e !== 'pays_emitidos:edit'
            && e !== 'comisiones:edit'
            && e !== 'markup:edit',
        )
      }
      setTimeout(() => {
        this.$store.state.app.showAdministration = !this.$store.state.app.showAdministration
      }, 10)
    },
    checkAllCreateBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showAdministration = false
      if (this.sourcePermisos[this.index].all.checkboxAdministrationCreate) {
        if (this.permisosLogin.includes('ctas_x_cobrar:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('ctas_x_cobrar:create')
        }
        if (this.permisosLogin.includes('ctas_x_pagar:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('ctas_x_pagar:create')
        }
        if (this.permisosLogin.includes('pays_recibidos:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('pays_recibidos:create')
        }
        if (this.permisosLogin.includes('pays_emitidos:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('pays_emitidos:create')
        }
        if (this.permisosLogin.includes('markup:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('markup:create')
        }
        if (this.permisosLogin.includes('finanzas:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('finanzas:create')
        }
        if (this.permisosLogin.includes('comisiones:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('comisiones:create')
        }
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'finanzas:create'
            && e !== 'ctas_x_cobrar:create'
            && e !== 'ctas_x_pagar:create'
            && e !== 'pays_recibidos:create'
            && e !== 'pays_emitidos:create'
            && e !== 'comisiones:create'
            && e !== 'markup:create',
        )
      }
      setTimeout(() => {
        this.$store.state.app.showAdministration = !this.$store.state.app.showAdministration
      }, 10)
    },
    checkAllDestroyBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showAdministration = false
      if (this.sourcePermisos[this.index].all.checkboxAdministrationDelete) {
        if (this.permisosLogin.includes('ctas_x_cobrar:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('ctas_x_cobrar:destroy')
        }
        if (this.permisosLogin.includes('ctas_x_pagar:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('ctas_x_pagar:destroy')
        }
        if (this.permisosLogin.includes('pays_recibidos:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('pays_recibidos:destroy')
        }
        if (this.permisosLogin.includes('pays_emitidos:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('pays_emitidos:destroy')
        }
        if (this.permisosLogin.includes('markup:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('markup:destroy')
        }
        if (this.permisosLogin.includes('finanzas:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('finanzas:destroy')
        }
        if (this.permisosLogin.includes('comisiones:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('comisiones:destroy')
        }
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'finanzas:destroy'
            && e !== 'ctas_x_cobrar:destroy'
            && e !== 'ctas_x_pagar:destroy'
            && e !== 'pays_recibidos:destroy'
            && e !== 'pays_emitidos:destroy'
            && e !== 'comisiones:destroy'
            && e !== 'markup:destroy',
        )
      }
      setTimeout(() => {
        this.$store.state.app.showAdministration = !this.$store.state.app.showAdministration
      }, 10)
    },
  },
}
</script>
