<template>
  <fragment>
    <!--LIST-->
    <td
      v-if="$store.state.app.showAPIs"
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxAPIsVer"
          hide-details
          class="my-1"
          @click="checkAllListBooking()"
        ></v-checkbox>
      </div>
    </td>
    <!--EDIT-->
    <td
      v-if="$store.state.app.showAPIs"
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxAPIsEdit"
          hide-details
          class="my-1"
          @click="checkAllEditBooking()"
        ></v-checkbox>
      </div>
    </td>
    <!--CREATE-->
    <td
      v-if="$store.state.app.showAPIs"
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxAPIsCreate"
          hide-details
          class="my-1"
          @click="checkAllCreateBooking()"
        ></v-checkbox>
      </div>
    </td>
    <!--DESTROY-->
    <td
      v-if="$store.state.app.showAPIs"
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxAPIsDelete"
          hide-details
          class="my-1"
          @click="checkAllDestroyBooking()"
        ></v-checkbox>
      </div>
    </td>
    <td
      :key="Math.random() * (index + 1)"
      style="min-width: 50px;"
    ></td>
  </fragment>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    index: {
      type: Number,
    },
  },
  data() {
    return {
      permisosLogin: localStorage.getItem('permisos_auth'),
      user: JSON.parse(localStorage.getItem('profile')),
    }
  },
  computed: {
    ...mapState({
      sourcePermisos: state => state.app.sourcePermisos,
    }),
  },
  methods: {
    checkAllListBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showAPIs = false
      if (this.sourcePermisos[this.index].all.checkboxAPIsVer) {
        if (this.permisosLogin.includes('api_keys:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_keys:list')
        }
        if (this.permisosLogin.includes('api_tti:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_tti:list')
        }
        if (this.permisosLogin.includes('api_amadeus:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_amadeus:list')
        }
        if (this.permisosLogin.includes('api_redsys:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_redsys:list')
        }
        if (this.permisosLogin.includes('api_sibs:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_sibs:list')
        }
        if (this.permisosLogin.includes('api_authorize_net:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_authorize_net:list')
        }
        if (this.permisosLogin.includes('api_paypal:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_paypal:list')
        }
        if (this.permisosLogin.includes('currencyapi:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('currencyapi:list')
        }
        if (this.permisosLogin.includes('api_hotetec:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_hotetec:list')
        }
        if (this.permisosLogin.includes('api_dingus:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_dingus:list')
        }
        if (this.permisosLogin.includes('api_solways:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_solways:list')
        }
        if (this.permisosLogin.includes('api_juniper:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_juniper:list')
        }
        if (this.permisosLogin.includes('api_copa_connect:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_copa_connect:list')
        }
        if (this.permisosLogin.includes('api_bigdata:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_bigdata:list')
        }
        if (this.permisosLogin.includes('api_deepl:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_deepl:list')
        }
        if (this.permisosLogin.includes('api_recaptcha:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_recaptcha:list')
        }
        if (this.permisosLogin.includes('api_google_translation:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_google_translation:list')
        }
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'api_keys:list'
            && e !== 'api_tti:list'
            && e !== 'api_amadeus:list'
            && e !== 'api_redsys:list'
            && e !== 'api_sibs:list'
            && e !== 'api_authorize_net:list'
            && e !== 'api_paypal:list'
            && e !== 'currencyapi:list'
            && e !== 'api_hotetec:list'
            && e !== 'api_dingus:list'
            && e !== 'api_solways:list'
            && e !== 'api_juniper:list'
            && e !== 'api_copa_connect:list'
            && e !== 'api_bigdata:list'
            && e !== 'api_deepl:list'
            && e !== 'api_recaptcha:list'
            && e !== 'api_google_translation:list',
        )
      }
      setTimeout(() => {
        this.$store.state.app.showAPIs = !this.$store.state.app.showAPIs
      }, 10)
    },
    checkAllEditBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showAPIs = false
      if (this.sourcePermisos[this.index].all.checkboxAPIsEdit) {
        if (this.permisosLogin.includes('api_keys:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_keys:edit')
        }
        if (this.permisosLogin.includes('api_tti:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_tti:edit')
        }
        if (this.permisosLogin.includes('api_amadeus:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_amadeus:edit')
        }
        if (this.permisosLogin.includes('api_redsys:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_redsys:edit')
        }
        if (this.permisosLogin.includes('api_sibs:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_sibs:edit')
        }
        if (this.permisosLogin.includes('api_authorize_net:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_authorize_net:edit')
        }
        if (this.permisosLogin.includes('api_paypal:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_paypal:edit')
        }
        if (this.permisosLogin.includes('currencyapi:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('currencyapi:edit')
        }
        if (this.permisosLogin.includes('api_hotetec:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_hotetec:edit')
        }
        if (this.permisosLogin.includes('api_dingus:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_dingus:edit')
        }
        if (this.permisosLogin.includes('api_solways:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_solways:edit')
        }
        if (this.permisosLogin.includes('api_juniper:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_juniper:edit')
        }
        if (this.permisosLogin.includes('api_copa_connect:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_copa_connect:edit')
        }
        if (this.permisosLogin.includes('api_bigdata:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_bigdata:edit')
        }
        if (this.permisosLogin.includes('api_deepl:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_deepl:edit')
        }
        if (this.permisosLogin.includes('api_recaptcha:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_recaptcha:edit')
        }
        if (this.permisosLogin.includes('api_google_translation:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_google_translation:edit')
        }
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'api_keys:edit'
            && e !== 'api_tti:edit'
            && e !== 'api_amadeus:edit'
            && e !== 'api_redsys:edit'
            && e !== 'api_sibs:edit'
            && e !== 'api_authorize_net:edit'
            && e !== 'api_paypal:edit'
            && e !== 'currencyapi:edit'
            && e !== 'api_hotetec:edit'
            && e !== 'api_dingus:edit'
            && e !== 'api_solways:edit'
            && e !== 'api_juniper:edit'
            && e !== 'api_copa_connect:edit'
            && e !== 'api_bigdata:edit'
            && e !== 'api_deepl:edit'
            && e !== 'api_recaptcha:edit'
            && e !== 'api_google_translation:edit',
        )
      }
      setTimeout(() => {
        this.$store.state.app.showAPIs = !this.$store.state.app.showAPIs
      }, 10)
    },
    checkAllCreateBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showAPIs = false
      if (this.sourcePermisos[this.index].all.checkboxAPIsCreate) {
        if (this.permisosLogin.includes('api_keys:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_keys:create')
        }
        if (this.permisosLogin.includes('api_tti:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_tti:create')
        }
        if (this.permisosLogin.includes('api_amadeus:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_amadeus:create')
        }
        if (this.permisosLogin.includes('api_redsys:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_redsys:create')
        }
        if (this.permisosLogin.includes('api_sibs:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_sibs:create')
        }
        if (this.permisosLogin.includes('api_authorize_net:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_authorize_net:create')
        }
        if (this.permisosLogin.includes('api_paypal:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_paypal:create')
        }
        if (this.permisosLogin.includes('currencyapi:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('currencyapi:create')
        }
        if (this.permisosLogin.includes('api_hotetec:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_hotetec:create')
        }
        if (this.permisosLogin.includes('api_dingus:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_dingus:create')
        }
        if (this.permisosLogin.includes('api_solways:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_solways:create')
        }
        if (this.permisosLogin.includes('api_juniper:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_juniper:create')
        }
        if (this.permisosLogin.includes('api_copa_connect:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_copa_connect:create')
        }
        if (this.permisosLogin.includes('api_bigdata:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_bigdata:create')
        }
        if (this.permisosLogin.includes('api_deepl:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_deepl:create')
        }
        if (this.permisosLogin.includes('api_recaptcha:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_recaptcha:create')
        }
        if (this.permisosLogin.includes('api_google_translation:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_google_translation:create')
        }
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'api_keys:create'
            && e !== 'api_tti:create'
            && e !== 'api_amadeus:create'
            && e !== 'api_redsys:create'
            && e !== 'api_sibs:create'
            && e !== 'api_authorize_net:create'
            && e !== 'api_paypal:create'
            && e !== 'currencyapi:create'
            && e !== 'api_hotetec:create'
            && e !== 'api_dingus:create'
            && e !== 'api_solways:create'
            && e !== 'api_juniper:create'
            && e !== 'api_copa_connect:create'
            && e !== 'api_bigdata:create'
            && e !== 'api_deepl:create'
            && e !== 'api_recaptcha:create'
            && e !== 'api_google_translation:create',
        )
      }
      setTimeout(() => {
        this.$store.state.app.showAPIs = !this.$store.state.app.showAPIs
      }, 10)
    },
    checkAllDestroyBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showAPIs = false
      if (this.sourcePermisos[this.index].all.checkboxAPIsDelete) {
        if (this.permisosLogin.includes('api_keys:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_keys:destroy')
        }
        if (this.permisosLogin.includes('api_tti:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_tti:destroy')
        }
        if (this.permisosLogin.includes('api_amadeus:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_amadeus:destroy')
        }
        if (this.permisosLogin.includes('api_redsys:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_redsys:destroy')
        }
        if (this.permisosLogin.includes('api_sibs:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_sibs:destroy')
        }
        if (this.permisosLogin.includes('api_authorize_net:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_authorize_net:destroy')
        }
        if (this.permisosLogin.includes('api_paypal:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_paypal:destroy')
        }
        if (this.permisosLogin.includes('currencyapi:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('currencyapi:destroy')
        }
        if (this.permisosLogin.includes('api_hotetec:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_hotetec:destroy')
        }
        if (this.permisosLogin.includes('api_dingus:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_dingus:destroy')
        }
        if (this.permisosLogin.includes('api_solways:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_solways:destroy')
        }
        if (this.permisosLogin.includes('api_juniper:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_juniper:destroy')
        }
        if (this.permisosLogin.includes('api_copa_connect:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_copa_connect:destroy')
        }
        if (this.permisosLogin.includes('api_bigdata:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_bigdata:destroy')
        }
        if (this.permisosLogin.includes('api_deepl:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_deepl:destroy')
        }
        if (this.permisosLogin.includes('api_recaptcha:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_recaptcha:destroy')
        }
        if (this.permisosLogin.includes('api_google_translation:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('api_google_translation:destroy')
        }
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'api_keys:destroy'
            && e !== 'api_tti:destroy'
            && e !== 'api_amadeus:destroy'
            && e !== 'api_redsys:destroy'
            && e !== 'api_sibs:destroy'
            && e !== 'api_authorize_net:destroy'
            && e !== 'api_paypal:destroy'
            && e !== 'currencyapi:destroy'
            && e !== 'api_hotetec:destroy'
            && e !== 'api_dingus:destroy'
            && e !== 'api_solways:destroy'
            && e !== 'api_juniper:destroy'
            && e !== 'api_copa_connect:destroy'
            && e !== 'api_bigdata:destroy'
            && e !== 'api_deepl:destroy'
            && e !== 'api_recaptcha:destroy'
            && e !== 'api_google_translation:destroy',
        )
      }
      setTimeout(() => {
        this.$store.state.app.showAPIs = !this.$store.state.app.showAPIs
      }, 10)
    },
  },
}
</script>
