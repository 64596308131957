<template>
  <fragment>
    <!--LIST-->
    <td
      v-if="$store.state.app.showNomencladoresFlights && $store.state.app.showNomencladores"
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxNomencladoresFlightVer"
          hide-details
          class="my-1"
          @click="checkAllListBooking()"
        ></v-checkbox>
      </div>
    </td>
    <!--EDIT-->
    <td
      v-if="$store.state.app.showNomencladoresFlights && $store.state.app.showNomencladores"
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxNomencladoresFlightEdit"
          hide-details
          class="my-1"
          @click="checkAllEditBooking()"
        ></v-checkbox>
      </div>
    </td>
    <!--CREATE-->
    <td
      v-if="$store.state.app.showNomencladoresFlights && $store.state.app.showNomencladores"
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxNomencladoresFlightCreate"
          hide-details
          class="my-1"
          @click="checkAllCreateBooking()"
        ></v-checkbox>
      </div>
    </td>
    <!--DESTROY-->
    <td
      v-if="$store.state.app.showNomencladoresFlights && $store.state.app.showNomencladores"
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxNomencladoresFlightDelete"
          hide-details
          class="my-1"
          @click="checkAllDestroyBooking()"
        ></v-checkbox>
      </div>
    </td>
    <td
      :key="Math.random() * (index + 1)"
      style="min-width: 50px;"
    ></td>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    index: {
      type: Number,
    },
  },
  data() {
    return {
      permisosLogin: localStorage.getItem('permisos_auth'),
      user: JSON.parse(localStorage.getItem('profile')),
    }
  },
  computed: {
    ...mapState({
      sourcePermisos: state => state.app.sourcePermisos,
    }),
  },
  methods: {
    ...mapMutations(['verifyPermisoZona']),
    checkAllListBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showNomencladoresFlights = false
      if (this.sourcePermisos[this.index].all.checkboxNomencladoresFlightVer) {
        if (this.permisosLogin.includes('nomenclators_tti:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nomenclators_tti:list')
        }
        if (this.permisosLogin.includes('nom_aerolines:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_aerolines:list')
        }
        if (this.permisosLogin.includes('airplane:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('airplane:list')
        }
        if (this.permisosLogin.includes('nom_clase_flights:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_clase_flights:list')
        }
        if (this.permisosLogin.includes('nom_tarifa_flights:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_tarifa_flights:list')
        }
        if (this.permisosLogin.includes('nom_dimensiones_flights:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_dimensiones_flights:list')
        }
        if (this.permisosLogin.includes('nom_peso_equipaje_flights:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_peso_equipaje_flights:list')
        }
        if (this.permisosLogin.includes('airport:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('airport:list')
        }
        if (this.permisosLogin.includes('nom_tarjeta_fidelidad_flights:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_tarjeta_fidelidad_flights:list')
        }
        if (this.permisosLogin.includes('nom_code_ofac_flights:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_code_ofac_flights:list')
        }
        if (this.permisosLogin.includes('nom_politica_tarifaria_flights:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_politica_tarifaria_flights:list')
        }
        if (this.permisosLogin.includes('nom_suplemento_contrate_fligths:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_suplemento_contrate_fligths:list')
        }
        if (this.permisosLogin.includes('nom_conditions_generals_fligths:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_conditions_generals_fligths:list')
        }
        this.verifyPermisoZona(this.index)
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'nom_aerolines:list'
            && e !== 'nomenclators_tti:list'
            && e !== 'airplane:list'
            && e !== 'nom_clase_flights:list'
            && e !== 'nom_tarifa_flights:list'
            && e !== 'nom_dimensiones_flights:list'
            && e !== 'nom_peso_equipaje_flights:list'
            && e !== 'airport:list'
            && e !== 'nom_tarjeta_fidelidad_flights:list'
            && e !== 'nom_code_ofac_flights:list'
            && e !== 'nom_politica_tarifaria_flights:list'
            && e !== 'nom_suplemento_contrate_fligths:list'
            && e !== 'nom_conditions_generals_fligths:list',
        )
        this.sourcePermisos[this.index].all.checkboxNomencladoresVer = false
      }
      setTimeout(() => {
        this.$store.state.app.showNomencladoresFlights = !this.$store.state.app.showNomencladoresFlights
      }, 10)
    },
    checkAllEditBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showNomencladoresFlights = false
      if (this.sourcePermisos[this.index].all.checkboxNomencladoresFlightEdit) {
        if (this.permisosLogin.includes('nomenclators_tti:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nomenclators_tti:edit')
        }
        if (this.permisosLogin.includes('nom_aerolines:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_aerolines:edit')
        }
        if (this.permisosLogin.includes('airplane:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('airplane:edit')
        }
        if (this.permisosLogin.includes('nom_clase_flights:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_clase_flights:edit')
        }
        if (this.permisosLogin.includes('nom_tarifa_flights:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_tarifa_flights:edit')
        }
        if (this.permisosLogin.includes('nom_dimensiones_flights:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_dimensiones_flights:edit')
        }
        if (this.permisosLogin.includes('nom_peso_equipaje_flights:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_peso_equipaje_flights:edit')
        }
        if (this.permisosLogin.includes('airport:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('airport:edit')
        }
        if (this.permisosLogin.includes('nom_tarjeta_fidelidad_flights:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_tarjeta_fidelidad_flights:edit')
        }
        if (this.permisosLogin.includes('nom_code_ofac_flights:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_code_ofac_flights:edit')
        }
        if (this.permisosLogin.includes('nom_politica_tarifaria_flights:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_politica_tarifaria_flights:edit')
        }
        if (this.permisosLogin.includes('nom_suplemento_contrate_fligths:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_suplemento_contrate_fligths:edit')
        }
        if (this.permisosLogin.includes('nom_conditions_generals_fligths:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_conditions_generals_fligths:edit')
        }
        this.verifyPermisoZona(this.index)
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'nom_aerolines:edit'
            && e !== 'nomenclators_tti:edit'
            && e !== 'airplane:edit'
            && e !== 'nom_clase_flights:edit'
            && e !== 'nom_tarifa_flights:edit'
            && e !== 'nom_dimensiones_flights:edit'
            && e !== 'nom_peso_equipaje_flights:edit'
            && e !== 'nom_tarjeta_fidelidad_flights:edit'
            && e !== 'nom_code_ofac_flights:edit'
            && e !== 'nom_politica_tarifaria_flights:edit'
            && e !== 'nom_suplemento_contrate_fligths:edit'
            && e !== 'nom_conditions_generals_fligths:edit',
        )
        this.sourcePermisos[this.index].all.checkboxNomencladoresEdit = false
      }
      setTimeout(() => {
        this.$store.state.app.showNomencladoresFlights = !this.$store.state.app.showNomencladoresFlights
      }, 10)
    },
    checkAllCreateBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showNomencladoresFlights = false
      if (this.sourcePermisos[this.index].all.checkboxNomencladoresFlightCreate) {
        if (this.permisosLogin.includes('nomenclators_tti:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nomenclators_tti:create')
        }
        if (this.permisosLogin.includes('nom_aerolines:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_aerolines:create')
        }
        if (this.permisosLogin.includes('airplane:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('airplane:create')
        }
        if (this.permisosLogin.includes('nom_clase_flights:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_clase_flights:create')
        }
        if (this.permisosLogin.includes('nom_tarifa_flights:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_tarifa_flights:create')
        }
        if (this.permisosLogin.includes('nom_dimensiones_flights:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_dimensiones_flights:create')
        }
        if (this.permisosLogin.includes('nom_peso_equipaje_flights:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_peso_equipaje_flights:create')
        }
        if (this.permisosLogin.includes('airport:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('airport:create')
        }
        if (this.permisosLogin.includes('nom_tarjeta_fidelidad_flights:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_tarjeta_fidelidad_flights:create')
        }
        if (this.permisosLogin.includes('nom_code_ofac_flights:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_code_ofac_flights:create')
        }
        if (this.permisosLogin.includes('nom_politica_tarifaria_flights:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_politica_tarifaria_flights:create')
        }
        if (this.permisosLogin.includes('nom_suplemento_contrate_fligths:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_suplemento_contrate_fligths:create')
        }
        if (this.permisosLogin.includes('nom_conditions_generals_fligths:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_conditions_generals_fligths:create')
        }
        this.verifyPermisoZona(this.index)
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'nom_aerolines:create'
            && e !== 'airplane:create'
            && e !== 'nomenclators_tti:create'
            && e !== 'nom_clase_flights:create'
            && e !== 'nom_tarifa_flights:create'
            && e !== 'nom_dimensiones_flights:create'
            && e !== 'nom_peso_equipaje_flights:create'
            && e !== 'nom_tarjeta_fidelidad_flights:create'
            && e !== 'nom_code_ofac_flights:create'
            && e !== 'nom_politica_tarifaria_flights:create'
            && e !== 'nom_suplemento_contrate_fligths:create'
            && e !== 'nom_conditions_generals_fligths:create',
        )
        this.sourcePermisos[this.index].all.checkboxNomencladoresCreate = false
      }
      setTimeout(() => {
        this.$store.state.app.showNomencladoresFlights = !this.$store.state.app.showNomencladoresFlights
      }, 10)
    },
    checkAllDestroyBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showNomencladoresFlights = false
      if (this.sourcePermisos[this.index].all.checkboxNomencladoresFlightDelete) {
        if (this.permisosLogin.includes('nomenclators_tti:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nomenclators_tti:destroy')
        }
        if (this.permisosLogin.includes('nom_aerolines:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_aerolines:destroy')
        }
        if (this.permisosLogin.includes('airplane:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('airplane:destroy')
        }
        if (this.permisosLogin.includes('nom_clase_flights:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_clase_flights:destroy')
        }
        if (this.permisosLogin.includes('nom_tarifa_flights:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_tarifa_flights:destroy')
        }
        if (this.permisosLogin.includes('nom_dimensiones_flights:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_dimensiones_flights:destroy')
        }
        if (this.permisosLogin.includes('nom_peso_equipaje_flights:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_peso_equipaje_flights:destroy')
        }
        if (this.permisosLogin.includes('airport:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('airport:destroy')
        }
        if (this.permisosLogin.includes('nom_tarjeta_fidelidad_flights:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_tarjeta_fidelidad_flights:destroy')
        }
        if (this.permisosLogin.includes('nom_code_ofac_flights:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_code_ofac_flights:destroy')
        }
        if (this.permisosLogin.includes('nom_politica_tarifaria_flights:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_politica_tarifaria_flights:destroy')
        }
        if (this.permisosLogin.includes('nom_suplemento_contrate_fligths:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_suplemento_contrate_fligths:destroy')
        }
        if (this.permisosLogin.includes('nom_conditions_generals_fligths:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_conditions_generals_fligths:destroy')
        }
        this.verifyPermisoZona(this.index)
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'nom_aerolines:destroy'
            && e !== 'nomenclators_tti:destroy'
            && e !== 'airplane:destroy'
            && e !== 'nomenclators_tti:destroy'
            && e !== 'nom_clase_flights:destroy'
            && e !== 'nom_tarifa_flights:destroy'
            && e !== 'nom_dimensiones_flights:destroy'
            && e !== 'nom_peso_equipaje_flights:destroy'
            && e !== 'nom_tarjeta_fidelidad_flights:destroy'
            && e !== 'nom_code_ofac_flights:destroy'
            && e !== 'nom_politica_tarifaria_flights:destroy'
            && e !== 'nom_suplemento_contrate_fligths:destroy'
            && e !== 'nom_conditions_generals_fligths:destroy',
        )
        this.sourcePermisos[this.index].all.checkboxNomencladoresDelete = false
      }
      setTimeout(() => {
        this.$store.state.app.showNomencladoresFlights = !this.$store.state.app.showNomencladoresFlights
      }, 10)
    },
  },
}
</script>
