<template>
  <fragment>
    <!--LIST-->
    <td
      v-if="$store.state.app.showNomencladoresHotels && $store.state.app.showNomencladores"
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxNomencladoresHotelsVer"
          hide-details
          class="my-1"
          @click="checkAllListBooking()"
        ></v-checkbox>
      </div>
    </td>
    <!--EDIT-->
    <td
      v-if="$store.state.app.showNomencladoresHotels && $store.state.app.showNomencladores"
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxNomencladoresHotelsEdit"
          hide-details
          class="my-1"
          @click="checkAllEditBooking()"
        ></v-checkbox>
      </div>
    </td>
    <!--CREATE-->
    <td
      v-if="$store.state.app.showNomencladoresHotels && $store.state.app.showNomencladores"
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxNomencladoresHotelsCreate"
          hide-details
          class="my-1"
          @click="checkAllCreateBooking()"
        ></v-checkbox>
      </div>
    </td>
    <!--DESTROY-->
    <td
      v-if="$store.state.app.showNomencladoresHotels && $store.state.app.showNomencladores"
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxNomencladoresHotelsDelete"
          hide-details
          class="my-1"
          @click="checkAllDestroyBooking()"
        ></v-checkbox>
      </div>
    </td>
    <td
      :key="Math.random() * (index + 1)"
      style="min-width: 50px;"
    ></td>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    index: {
      type: Number,
    },
  },
  data() {
    return {
      permisosLogin: localStorage.getItem('permisos_auth'),
      user: JSON.parse(localStorage.getItem('profile')),
    }
  },
  computed: {
    ...mapState({
      sourcePermisos: state => state.app.sourcePermisos,
    }),
  },
  methods: {
    ...mapMutations(['verifyPermisoZona']),
    checkAllListBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showNomencladoresHotels = false
      if (this.sourcePermisos[this.index].all.checkboxNomencladoresHotelsVer) {
        if (this.permisosLogin.includes('nom_tipo_destino_hotels:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_tipo_destino_hotels:list')
        }
        if (this.permisosLogin.includes('nom_operador_hotels:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_operador_hotels:list')
        }
        if (this.permisosLogin.includes('nom_marca_asociada_hotels:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_marca_asociada_hotels:list')
        }
        if (this.permisosLogin.includes('nom_categorias_hotels:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_categorias_hotels:list')
        }
        if (this.permisosLogin.includes('nom_intereses_hotels:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_intereses_hotels:list')
        }
        if (this.permisosLogin.includes('nom_servicios_hotels:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_servicios_hotels:list')
        }
        if (this.permisosLogin.includes('nom_habitacion_hotels:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_habitacion_hotels:list')
        }
        if (this.permisosLogin.includes('nom_regimen_alimenticio:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_regimen_alimenticio:list')
        }
        if (this.permisosLogin.includes('nom_edad_hotels:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_edad_hotels:list')
        }
        if (this.permisosLogin.includes('nom_tag_imagen_hotels:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_tag_imagen_hotels:list')
        }
        if (this.permisosLogin.includes('nom_suplemento_contrate_hotels:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_suplemento_contrate_hotels:list')
        }
        if (this.permisosLogin.includes('nom_reducciones_contrate_hotels:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_reducciones_contrate_hotels:list')
        }
        if (this.permisosLogin.includes('nom_conditions_generals_hotels:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_conditions_generals_hotels:list')
        }
        if (this.permisosLogin.includes('nom_categories_tarifarios_hotels:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_categories_tarifarios_hotels:list')
        }
        this.verifyPermisoZona(this.index)
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'nom_tipo_destino_hotels:list'
            && e !== 'nom_operador_hotels:list'
            && e !== 'nom_marca_asociada_hotels:list'
            && e !== 'nom_categorias_hotels:list'
            && e !== 'nom_intereses_hotels:list'
            && e !== 'nom_servicios_hotels:list'
            && e !== 'nom_habitacion_hotels:list'
            && e !== 'nom_regimen_alimenticio:list'
            && e !== 'nom_edad_hotels:list'
            && e !== 'nom_tag_imagen_hotels:list'
            && e !== 'nom_suplemento_contrate_hotels:list'
            && e !== 'nom_reducciones_contrate_hotels:list'
            && e !== 'nom_conditions_generals_hotels:list'
            && e !== 'nom_categories_tarifarios_hotels:list',
        )
        this.sourcePermisos[this.index].all.checkboxNomencladoresVer = false
      }
      setTimeout(() => {
        this.$store.state.app.showNomencladoresHotels = !this.$store.state.app.showNomencladoresHotels
      }, 10)
    },
    checkAllEditBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showNomencladoresHotels = false
      if (this.sourcePermisos[this.index].all.checkboxNomencladoresHotelsEdit) {
        if (this.permisosLogin.includes('nom_tipo_destino_hotels:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_tipo_destino_hotels:edit')
        }
        if (this.permisosLogin.includes('nom_operador_hotels:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_operador_hotels:edit')
        }
        if (this.permisosLogin.includes('nom_marca_asociada_hotels:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_marca_asociada_hotels:edit')
        }
        if (this.permisosLogin.includes('nom_categorias_hotels:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_categorias_hotels:edit')
        }
        if (this.permisosLogin.includes('nom_intereses_hotels:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_intereses_hotels:edit')
        }
        if (this.permisosLogin.includes('nom_servicios_hotels:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_servicios_hotels:edit')
        }
        if (this.permisosLogin.includes('nom_habitacion_hotels:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_habitacion_hotels:edit')
        }
        if (this.permisosLogin.includes('nom_regimen_alimenticio:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_regimen_alimenticio:edit')
        }
        if (this.permisosLogin.includes('nom_edad_hotels:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_edad_hotels:edit')
        }
        if (this.permisosLogin.includes('nom_tag_imagen_hotels:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_tag_imagen_hotels:edit')
        }
        if (this.permisosLogin.includes('nom_suplemento_contrate_hotels:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_suplemento_contrate_hotels:edit')
        }
        if (this.permisosLogin.includes('nom_reducciones_contrate_hotels:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_reducciones_contrate_hotels:edit')
        }
        if (this.permisosLogin.includes('nom_conditions_generals_hotels:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_conditions_generals_hotels:edit')
        }
        if (this.permisosLogin.includes('nom_categories_tarifarios_hotels:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_categories_tarifarios_hotels:edit')
        }
        this.verifyPermisoZona(this.index)
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'nom_tipo_destino_hotels:edit'
            && e !== 'nom_operador_hotels:edit'
            && e !== 'nom_marca_asociada_hotels:edit'
            && e !== 'nom_categorias_hotels:edit'
            && e !== 'nom_intereses_hotels:edit'
            && e !== 'nom_servicios_hotels:edit'
            && e !== 'nom_habitacion_hotels:edit'
            && e !== 'nom_regimen_alimenticio:edit'
            && e !== 'nom_edad_hotels:edit'
            && e !== 'nom_tag_imagen_hotels:edit'
            && e !== 'nom_suplemento_contrate_hotels:edit'
            && e !== 'nom_reducciones_contrate_hotels:edit'
            && e !== 'nom_conditions_generals_hotels:edit'
            && e !== 'nom_categories_tarifarios_hotels:edit',
        )
        this.sourcePermisos[this.index].all.checkboxNomencladoresEdit = false
      }
      setTimeout(() => {
        this.$store.state.app.showNomencladoresHotels = !this.$store.state.app.showNomencladoresHotels
      }, 10)
    },
    checkAllCreateBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showNomencladoresHotels = false
      if (this.sourcePermisos[this.index].all.checkboxNomencladoresHotelsCreate) {
        if (this.permisosLogin.includes('nom_tipo_destino_hotels:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_tipo_destino_hotels:create')
        }
        if (this.permisosLogin.includes('nom_operador_hotels:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_operador_hotels:create')
        }
        if (this.permisosLogin.includes('nom_marca_asociada_hotels:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_marca_asociada_hotels:create')
        }
        if (this.permisosLogin.includes('nom_categorias_hotels:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_categorias_hotels:create')
        }
        if (this.permisosLogin.includes('nom_intereses_hotels:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_intereses_hotels:create')
        }
        if (this.permisosLogin.includes('nom_servicios_hotels:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_servicios_hotels:create')
        }
        if (this.permisosLogin.includes('nom_habitacion_hotels:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_habitacion_hotels:create')
        }
        if (this.permisosLogin.includes('nom_regimen_alimenticio:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_regimen_alimenticio:create')
        }
        if (this.permisosLogin.includes('nom_edad_hotels:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_edad_hotels:create')
        }
        if (this.permisosLogin.includes('nom_tag_imagen_hotels:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_tag_imagen_hotels:create')
        }
        if (this.permisosLogin.includes('nom_suplemento_contrate_hotels:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_suplemento_contrate_hotels:create')
        }
        if (this.permisosLogin.includes('nom_reducciones_contrate_hotels:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_reducciones_contrate_hotels:create')
        }
        if (this.permisosLogin.includes('nom_conditions_generals_hotels:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_conditions_generals_hotels:create')
        }
        if (this.permisosLogin.includes('nom_categories_tarifarios_hotels:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_categories_tarifarios_hotels:create')
        }
        this.verifyPermisoZona(this.index)
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'nom_tipo_destino_hotels:create'
            && e !== 'nom_operador_hotels:create'
            && e !== 'nom_marca_asociada_hotels:create'
            && e !== 'nom_categorias_hotels:create'
            && e !== 'nom_intereses_hotels:create'
            && e !== 'nom_servicios_hotels:create'
            && e !== 'nom_habitacion_hotels:create'
            && e !== 'nom_regimen_alimenticio:create'
            && e !== 'nom_edad_hotels:create'
            && e !== 'nom_tag_imagen_hotels:create'
            && e !== 'nom_suplemento_contrate_hotels:create'
            && e !== 'nom_reducciones_contrate_hotels:create'
            && e !== 'nom_conditions_generals_hotels:create'
            && e !== 'nom_categories_tarifarios_hotels:create',
        )
        this.sourcePermisos[this.index].all.checkboxNomencladoresCreate = false
      }
      setTimeout(() => {
        this.$store.state.app.showNomencladoresHotels = !this.$store.state.app.showNomencladoresHotels
      }, 10)
    },
    checkAllDestroyBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showNomencladoresHotels = false
      if (this.sourcePermisos[this.index].all.checkboxNomencladoresHotelsDelete) {
        if (this.permisosLogin.includes('nom_tipo_destino_hotels:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_tipo_destino_hotels:destroy')
        }
        if (this.permisosLogin.includes('nom_operador_hotels:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_operador_hotels:destroy')
        }
        if (this.permisosLogin.includes('nom_marca_asociada_hotels:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_marca_asociada_hotels:destroy')
        }
        if (this.permisosLogin.includes('nom_categorias_hotels:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_categorias_hotels:destroy')
        }
        if (this.permisosLogin.includes('nom_intereses_hotels:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_intereses_hotels:destroy')
        }
        if (this.permisosLogin.includes('nom_servicios_hotels:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_servicios_hotels:destroy')
        }
        if (this.permisosLogin.includes('nom_habitacion_hotels:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_habitacion_hotels:destroy')
        }
        if (this.permisosLogin.includes('nom_regimen_alimenticio:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_regimen_alimenticio:destroy')
        }
        if (this.permisosLogin.includes('nom_edad_hotels:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_edad_hotels:destroy')
        }
        if (this.permisosLogin.includes('nom_tag_imagen_hotels:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_tag_imagen_hotels:destroy')
        }
        if (this.permisosLogin.includes('nom_suplemento_contrate_hotels:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_suplemento_contrate_hotels:destroy')
        }
        if (this.permisosLogin.includes('nom_reducciones_contrate_hotels:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_reducciones_contrate_hotels:destroy')
        }
        if (this.permisosLogin.includes('nom_conditions_generals_hotels:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_conditions_generals_hotels:destroy')
        }
        if (this.permisosLogin.includes('nom_categories_tarifarios_hotels:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('nom_categories_tarifarios_hotels:destroy')
        }
        this.verifyPermisoZona(this.index)
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'nom_tipo_destino_hotels:destroy'
            && e !== 'nom_operador_hotels:destroy'
            && e !== 'nom_marca_asociada_hotels:destroy'
            && e !== 'nom_categorias_hotels:destroy'
            && e !== 'nom_intereses_hotels:destroy'
            && e !== 'nom_servicios_hotels:destroy'
            && e !== 'nom_habitacion_hotels:destroy'
            && e !== 'nom_regimen_alimenticio:destroy'
            && e !== 'nom_edad_hotels:destroy'
            && e !== 'nom_tag_imagen_hotels:destroy'
            && e !== 'nom_suplemento_contrate_hotels:destroy'
            && e !== 'nom_reducciones_contrate_hotels:destroy'
            && e !== 'nom_conditions_generals_hotels:destroy'
            && e !== 'nom_categories_tarifarios_hotels:destroy',
        )
        this.sourcePermisos[this.index].all.checkboxNomencladoresDelete = false
      }
      setTimeout(() => {
        this.$store.state.app.showNomencladoresHotels = !this.$store.state.app.showNomencladoresHotels
      }, 10)
    },
  },
}
</script>
