<template>
  <fragment>
    <!--LIST-->
    <td
      v-if="$store.state.app.showContrate"
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxContrateVer"
          hide-details
          class="my-1"
          @click="checkAllListBooking()"
        ></v-checkbox>
      </div>
    </td>
    <!--EDIT-->
    <td
      v-if="$store.state.app.showContrate"
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxContrateEdit"
          hide-details
          class="my-1"
          @click="checkAllEditBooking()"
        ></v-checkbox>
      </div>
    </td>
    <!--CREATE-->
    <td
      v-if="$store.state.app.showContrate"
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxContrateCreate"
          hide-details
          class="my-1"
          @click="checkAllCreateBooking()"
        ></v-checkbox>
      </div>
    </td>
    <!--DESTROY-->
    <td
      v-if="$store.state.app.showContrate"
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxContrateDelete"
          hide-details
          class="my-1"
          @click="checkAllDestroyBooking()"
        ></v-checkbox>
      </div>
    </td>
    <td
      :key="Math.random() * (index + 1)"
      style="min-width: 50px;"
    ></td>
  </fragment>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    index: {
      type: Number,
    },
  },
  data() {
    return {
      permisosLogin: localStorage.getItem('permisos_auth'),
      user: JSON.parse(localStorage.getItem('profile')),
    }
  },
  computed: {
    ...mapState({
      sourcePermisos: state => state.app.sourcePermisos,
    }),
  },
  methods: {
    checkAllListBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showContrate = false
      if (this.sourcePermisos[this.index].all.checkboxContrateVer) {
        if (this.permisosLogin.includes('contrate_flights:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('contrate_flights:list')
        }
        if (this.permisosLogin.includes('contrate_hotels:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('contrate_hotels:list')
        }
        if (this.permisosLogin.includes('contrate_cars:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('contrate_cars:list')
        }
        if (this.permisosLogin.includes('stopSale:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('stopSale:list')
        }
        if (this.permisosLogin.includes('oferts:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('oferts:list')
        }
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'contrate_flights:list'
            && e !== 'contrate_hotels:list'
            && e !== 'contrate_cars:list'
            && e !== 'stopSale:list'
            && e !== 'oferts:list',
        )
      }
      setTimeout(() => {
        this.$store.state.app.showContrate = !this.$store.state.app.showContrate
      }, 10)
    },
    checkAllEditBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showContrate = false
      if (this.sourcePermisos[this.index].all.checkboxContrateEdit) {
        if (this.permisosLogin.includes('contrate_flights:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('contrate_flights:edit')
        }
        if (this.permisosLogin.includes('contrate_hotels:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('contrate_hotels:edit')
        }
        if (this.permisosLogin.includes('contrate_cars:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('contrate_cars:edit')
        }
        if (this.permisosLogin.includes('stopSale:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('stopSale:edit')
        }
        if (this.permisosLogin.includes('oferts:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('oferts:edit')
        }
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'contrate_flights:edit'
            && e !== 'contrate_hotels:edit'
            && e !== 'contrate_cars:edit'
            && e !== 'stopSale:edit'
            && e !== 'oferts:edit',
        )
      }
      setTimeout(() => {
        this.$store.state.app.showContrate = !this.$store.state.app.showContrate
      }, 10)
    },
    checkAllCreateBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showContrate = false
      if (this.sourcePermisos[this.index].all.checkboxContrateCreate) {
        if (this.permisosLogin.includes('contrate_flights:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('contrate_flights:create')
        }
        if (this.permisosLogin.includes('contrate_hotels:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('contrate_hotels:create')
        }
        if (this.permisosLogin.includes('contrate_cars:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('contrate_cars:create')
        }
        if (this.permisosLogin.includes('stopSale:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('stopSale:create')
        }
        if (this.permisosLogin.includes('oferts:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('oferts:create')
        }
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'contrate_flights:create'
            && e !== 'contrate_hotels:create'
            && e !== 'contrate_cars:create'
            && e !== 'stopSale:create'
            && e !== 'oferts:create',
        )
      }
      setTimeout(() => {
        this.$store.state.app.showContrate = !this.$store.state.app.showContrate
      }, 10)
    },
    checkAllDestroyBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showContrate = false
      if (this.sourcePermisos[this.index].all.checkboxContrateDelete) {
        if (this.permisosLogin.includes('contrate_flights:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('contrate_flights:destroy')
        }
        if (this.permisosLogin.includes('contrate_hotels:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('contrate_hotels:destroy')
        }
        if (this.permisosLogin.includes('contrate_cars:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('contrate_cars:destroy')
        }
        if (this.permisosLogin.includes('stopSale:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('stopSale:destroy')
        }
        if (this.permisosLogin.includes('oferts:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('oferts:destroy')
        }
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'contrate_flights:destroy'
            && e !== 'contrate_hotels:destroy'
            && e !== 'contrate_cars:destroy'
            && e !== 'stopSale:destroy'
            && e !== 'oferts:destroy',
        )
      }
      setTimeout(() => {
        this.$store.state.app.showContrate = !this.$store.state.app.showContrate
      }, 10)
    },
  },
}
</script>
