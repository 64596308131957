<template>
  <fragment>
    <!--LIST-->
    <td
      v-if="$store.state.app.showConfiguration"
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxConfigurationVer"
          hide-details
          class="my-1"
          @click="checkAllListBooking()"
        ></v-checkbox>
      </div>
    </td>
    <!--EDIT-->
    <td
      v-if="$store.state.app.showConfiguration"
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxConfigurationEdit"
          hide-details
          class="my-1"
          @click="checkAllEditBooking()"
        ></v-checkbox>
      </div>
    </td>
    <!--CREATE-->
    <td
      v-if="$store.state.app.showConfiguration"
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxConfigurationCreate"
          hide-details
          class="my-1"
          @click="checkAllCreateBooking()"
        ></v-checkbox>
      </div>
    </td>
    <!--DESTROY-->
    <td
      v-if="$store.state.app.showConfiguration"
      :key="Math.random() * (index + 1)"
      class="paddMine"
    >
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="sourcePermisos[index].all.checkboxConfigurationDelete"
          hide-details
          class="my-1"
          @click="checkAllDestroyBooking()"
        ></v-checkbox>
      </div>
    </td>
    <td
      :key="Math.random() * (index + 1)"
      style="min-width: 50px;"
    ></td>
  </fragment>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    index: {
      type: Number,
    },
  },
  data() {
    return {
      permisosLogin: localStorage.getItem('permisos_auth'),
      user: JSON.parse(localStorage.getItem('profile')),
    }
  },
  computed: {
    ...mapState({
      sourcePermisos: state => state.app.sourcePermisos,
    }),
  },
  methods: {
    checkAllListBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showConfiguration = false
      if (this.sourcePermisos[this.index].all.checkboxConfigurationVer) {
        if (this.permisosLogin.includes('config_general:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('config_general:list')
        }
        if (this.permisosLogin.includes('perfil_afiliado:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('perfil_afiliado:list')
        }
        if (this.permisosLogin.includes('user_register:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('user_register:list')
        }
        if (this.permisosLogin.includes('role:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('role:list')
        }
        if (this.permisosLogin.includes('user:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('user:list')
        }
        if (this.permisosLogin.includes('users_son:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('users_son:list')
        }
        if (this.permisosLogin.includes('agente:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('agente:list')
        }
        if (this.permisosLogin.includes('catalogs:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('catalogs:list')
        }
        if (this.permisosLogin.includes('events:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('events:list')
        }
        if (this.permisosLogin.includes('traslate_pages:list') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('traslate_pages:list')
        }
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'config_general:list'
            && e !== 'perfil_afiliado:list'
            && e !== 'user_register:list'
            && e !== 'role:list'
            && e !== 'user:list'
            && e !== 'users_son:list'
            && e !== 'agente:list'
            && e !== 'catalogs:list'
            && e !== 'events:list'
            && e !== 'traslate_pages:list',
        )
      }
      setTimeout(() => {
        this.$store.state.app.showConfiguration = !this.$store.state.app.showConfiguration
      }, 10)
    },
    checkAllEditBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showConfiguration = false
      if (this.sourcePermisos[this.index].all.checkboxConfigurationEdit) {
        if (this.permisosLogin.includes('config_general:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('config_general:edit')
        }
        if (this.permisosLogin.includes('perfil_afiliado:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('perfil_afiliado:edit')
        }
        if (this.permisosLogin.includes('user_register:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('user_register:edit')
        }
        if (this.permisosLogin.includes('role:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('role:edit')
        }
        if (this.permisosLogin.includes('user:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('user:edit')
        }
        if (this.permisosLogin.includes('users_son:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('users_son:edit')
        }
        if (this.permisosLogin.includes('agente:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('agente:edit')
        }
        if (this.permisosLogin.includes('catalogs:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('catalogs:edit')
        }
        if (this.permisosLogin.includes('events:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('events:edit')
        }
        if (this.permisosLogin.includes('traslate_pages:edit') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('traslate_pages:edit')
        }
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'config_general:edit'
            && e !== 'perfil_afiliado:edit'
            && e !== 'user_register:edit'
            && e !== 'role:edit'
            && e !== 'user:edit'
            && e !== 'users_son:edit'
            && e !== 'agente:edit'
            && e !== 'catalogs:edit'
            && e !== 'events:edit'
            && e !== 'traslate_pages:edit',
        )
      }
      setTimeout(() => {
        this.$store.state.app.showConfiguration = !this.$store.state.app.showConfiguration
      }, 10)
    },
    checkAllCreateBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showConfiguration = false
      if (this.sourcePermisos[this.index].all.checkboxConfigurationCreate) {
        if (this.permisosLogin.includes('config_general:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('config_general:create')
        }
        if (this.permisosLogin.includes('perfil_afiliado:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('perfil_afiliado:create')
        }
        if (this.permisosLogin.includes('user_register:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('user_register:create')
        }
        if (this.permisosLogin.includes('role:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('role:create')
        }
        if (this.permisosLogin.includes('user:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('user:create')
        }
        if (this.permisosLogin.includes('users_son:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('users_son:create')
        }
        if (this.permisosLogin.includes('agente:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('agente:create')
        }
        if (this.permisosLogin.includes('catalogs:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('catalogs:create')
        }
        if (this.permisosLogin.includes('events:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('events:create')
        }
        if (this.permisosLogin.includes('traslate_pages:create') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('traslate_pages:create')
        }
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'config_general:create'
            && e !== 'perfil_afiliado:create'
            && e !== 'user_register:create'
            && e !== 'role:create'
            && e !== 'user:create'
            && e !== 'users_son:create'
            && e !== 'agente:create'
            && e !== 'catalogs:create'
            && e !== 'events:create'
            && e !== 'traslate_pages:create',
        )
      }
      setTimeout(() => {
        this.$store.state.app.showConfiguration = !this.$store.state.app.showConfiguration
      }, 10)
    },
    checkAllDestroyBooking() {
      const { permisos } = this.sourcePermisos[this.index]
      this.$store.state.app.showConfiguration = false
      if (this.sourcePermisos[this.index].all.checkboxConfigurationDelete) {
        if (this.permisosLogin.includes('config_general:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('config_general:destroy')
        }
        if (this.permisosLogin.includes('perfil_afiliado:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('perfil_afiliado:destroy')
        }
        if (this.permisosLogin.includes('user_register:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('user_register:destroy')
        }
        if (this.permisosLogin.includes('role:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('role:destroy')
        }
        if (this.permisosLogin.includes('user:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('user:destroy')
        }
        if (this.permisosLogin.includes('users_son:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('users_son:destroy')
        }
        if (this.permisosLogin.includes('agente:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('agente:destroy')
        }
        if (this.permisosLogin.includes('catalogs:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('catalogs:destroy')
        }
        if (this.permisosLogin.includes('events:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('events:destroy')
        }
        if (this.permisosLogin.includes('traslate_pages:destroy') || this.user.nivel === -1) {
          this.sourcePermisos[this.index].permisos.push('traslate_pages:destroy')
        }
      } else {
        this.sourcePermisos[this.index].permisos = permisos.filter(
          e => e !== 'config_general:destroy'
            && e !== 'perfil_afiliado:destroy'
            && e !== 'user_register:destroy'
            && e !== 'role:destroy'
            && e !== 'user:destroy'
            && e !== 'users_son:destroy'
            && e !== 'agente:destroy'
            && e !== 'catalogs:destroy'
            && e !== 'events:destroy'
            && e !== 'traslate_pages:destroy',
        )
      }
      setTimeout(() => {
        this.$store.state.app.showConfiguration = !this.$store.state.app.showConfiguration
      }, 10)
    },
  },
}
</script>
